/*!==============================
 * Theme Name: Enlight
 * Theme URL: https://probootstrap.com/enlight-free-education-responsive-bootstrap-website-template
 * Author: ProBootstrap.com
 * Author URL: https://probootstrap.com
 * License: Released for free under the Creative Commons Attribution 3.0 license (probootstrap.com/license)
 */
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?1z9v6x");
  src: url("../fonts/icomoon/icomoon.eot?1z9v6x#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?1z9v6x") format("truetype"), url("../fonts/icomoon/icomoon.woff?1z9v6x") format("woff"), url("../fonts/icomoon/icomoon.svg?1z9v6x#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.homepage {
  /* Flexslider override style */
  /* Owl carousel override style */
  /* This block of CSS adds opacity transition to background */
  /* padding-bottom and top for image */
  /* position of shadow behind the image */
  /* padding for main container */
  /* aligns caption to center */
}

.homepage html {
  overflow-x: hidden;
}

.homepage body {
  background: #eff0f4;
  color: #8b8e94;
  line-height: 28px;
  font-size: 15px;
  font-family: "Raleway", Arial, sans-serif;
}

.homepage h1,
.homepage h2,
.homepage h3,
.homepage h4,
.homepage h5,
.homepage h6 {
  color: #181920;
  line-height: 1.5;
  font-family: "Raleway", Arial, sans-serif;
  font-weight: 300;
  margin: 0 0 30px 0;
}

.homepage h1 {
  font-size: 46px;
}

.homepage h2 {
  font-size: 36px;
}

.homepage h3 {
  font-size: 28px;
}

.homepage h4 {
  font-size: 36px;
}

.homepage h5,
.homepage h6 {
  font-size: 14px;
}

.homepage p,
.homepage ul,
.homepage ol {
  margin-bottom: 30px;
}

.homepage a {
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  color: #6a41ed;
}

.homepage a:hover, .homepage a:focus, .homepage a:active {
  color: #582aeb;
  text-decoration: none;
}

.homepage .hero-text-heading, .homepage .probootstrap-hero h1, .homepage .flexslider .probootstrap-heading {
  font-size: 52px;
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 0;
  line-height: 64px;
  color: white;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .homepage .hero-text-heading, .homepage .probootstrap-hero h1, .homepage .flexslider .probootstrap-heading {
    font-size: 40px;
    line-height: 1.5;
  }
}

@media screen and (max-width: 480px) {
  .homepage .hero-text-heading, .homepage .probootstrap-hero h1, .homepage .flexslider .probootstrap-heading {
    font-size: 30px;
  }
}

.homepage .hero-text-subheading, .homepage .probootstrap-hero p, .homepage .flexslider .probootstrap-subheading {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
}

.homepage .hero-text-subheading:last-child, .homepage .probootstrap-hero p:last-child, .homepage .flexslider .probootstrap-subheading:last-child {
  margin-bottom: 0;
}

.homepage .probootstrap-page-wrapper {
  max-width: 1500px;
  margin: 0 auto;
  background: #ffffff;
}

@media screen and (max-width: 1500px) {
  .homepage .probootstrap-page-wrapper {
    max-width: 1200px;
  }
  .homepage .probootstrap-page-wrapper .container {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1200px) {
  .homepage .probootstrap-page-wrapper {
    max-width: 960px;
  }
  .homepage .probootstrap-page-wrapper .container {
    max-width: 900px;
  }
}

.homepage .probootstrap-sidebar-inner {
  background: #ffffff;
  padding: 40px;
  margin-bottom: 40px;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.homepage .probootstrap-side-menu {
  padding: 0;
  margin: 0;
}

.homepage .probootstrap-side-menu li {
  padding: 0;
  margin: 0 0 20px 0;
  list-style: none;
  font-size: 16px;
}

.homepage .probootstrap-side-menu li.active {
  color: #000000;
}

.homepage .probootstrap-side-menu li.active a {
  color: #000000;
}

.homepage .probootstrap-header-top {
  margin-top: 0;
  position: relative;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-header-top {
    display: none;
  }
  .homepage .probootstrap-header-top.active {
    display: block;
  }
}

.homepage .probootstrap-header-top > .container {
  border-bottom: 1px solid #f2f2f2;
}

.homepage .probootstrap-top-quick-contact-info {
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 12px;
}

.homepage .probootstrap-top-quick-contact-info span {
  display: inline-block;
  margin-right: 20px;
}

.homepage .probootstrap-top-quick-contact-info span i {
  display: inline-block;
  margin-right: 10px;
  color: #49D292;
}

.homepage .probootstrap-top-social {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-top-social {
    text-align: left;
  }
}

.homepage .probootstrap-top-social ul {
  padding: 0;
  margin: 5px 0 0 0;
}

.homepage .probootstrap-top-social ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.homepage .probootstrap-top-social ul li a {
  display: inline-block;
  padding: 4px;
  color: #49D292;
}

.homepage .probootstrap-top-social ul li:last-child a {
  padding-right: 0;
}

.homepage .probootstrap-search-icon {
  margin-left: 30px;
}

.homepage .probootstrap-navbar {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0px;
  margin-bottom: 0px;
  background: #ffffff;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar {
    background: white;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
}

.homepage .probootstrap-navbar .btn-more {
  position: absolute;
  right: 80px;
  top: 13px;
  z-index: 100;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-navbar .btn-more {
    right: 70px;
  }
}

.homepage .probootstrap-navbar .btn-more a {
  color: #b3b3b3;
}

.homepage .probootstrap-navbar .btn-more a:hover {
  color: #1a1a1a;
}

.homepage .probootstrap-navbar .navbar-toggle:focus,
.homepage .probootstrap-navbar .navbar-toggle:hover {
  background-color: transparent;
}

.homepage .probootstrap-navbar .navbar-toggle {
  border: none;
}

.homepage .probootstrap-navbar .navbar-toggle span.icon-bar {
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
}

.homepage .probootstrap-navbar .navbar-toggle span:nth-child(2) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
          transform-origin: 10% 10%;
}

.homepage .probootstrap-navbar .navbar-toggle span:nth-child(3) {
  opacity: 0;
}

.homepage .probootstrap-navbar .navbar-toggle span:nth-child(4) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
          transform-origin: 10% 90%;
}

.homepage .probootstrap-navbar .navbar-toggle.collapsed span:nth-child(2),
.homepage .probootstrap-navbar .navbar-toggle.collapsed span:nth-child(4) {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.homepage .probootstrap-navbar .navbar-toggle.collapsed span:nth-child(3) {
  opacity: 1;
}

.homepage .probootstrap-navbar .parent-nav-link-padding, .homepage .probootstrap-navbar .navbar-brand, .homepage .probootstrap-navbar .navbar-nav > li > a {
  padding-top: 34px;
  padding-bottom: 34px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 15px;
  margin-right: 15px;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar .parent-nav-link-padding, .homepage .probootstrap-navbar .navbar-brand, .homepage .probootstrap-navbar .navbar-nav > li > a {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}

.homepage .probootstrap-navbar .dropdown-header {
  color: rgba(255, 255, 255, 0.2);
  padding-left: 10px;
  text-transform: uppercase;
}

.homepage .probootstrap-navbar .dropdown > a {
  padding-right: 10px !important;
  position: relative;
  display: block;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-navbar .dropdown > a {
    display: block;
    padding-right: 0px;
  }
}

.homepage .probootstrap-navbar .dropdown > a:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: -10px;
  margin-top: -11px;
  content: "\e924";
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar .dropdown > a:before {
    color: rgba(0, 0, 0, 0.2);
  }
}

.homepage .probootstrap-navbar .dropdown > a:hover:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.homepage .probootstrap-navbar .dropdown.open > a, .homepage .probootstrap-navbar .dropdown:hover > a, .homepage .probootstrap-navbar .dropdown:focus > a, .homepage .probootstrap-navbar .dropdown:active > a {
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.homepage .probootstrap-navbar .dropdown.open > a:before, .homepage .probootstrap-navbar .dropdown:hover > a:before, .homepage .probootstrap-navbar .dropdown:focus > a:before, .homepage .probootstrap-navbar .dropdown:active > a:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.homepage .probootstrap-navbar .dropdown > .dropdown-menu > li a {
  padding: 10px;
  color: rgba(255, 255, 255, 0.8);
}

.homepage .probootstrap-navbar .dropdown > .dropdown-menu > li a:hover {
  border-top: none;
  background: #ffffff;
  color: #49D292;
}

.homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.open > a, .homepage .probootstrap-navbar .dropdown > .dropdown-menu > li:hover > a, .homepage .probootstrap-navbar .dropdown > .dropdown-menu > li:focus > a, .homepage .probootstrap-navbar .dropdown > .dropdown-menu > li:active > a {
  border-top: none;
  color: #49D292;
  background: #ffffff;
}

.homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu.open > a, .homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:hover > a, .homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:focus > a, .homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:active > a {
  border-top: none;
}

.homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu.open > a:before, .homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:hover > a:before, .homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:focus > a:before, .homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:active > a:before {
  color: #000000;
}

.homepage .probootstrap-navbar .dropdown > .dropdown-menu > li.active > a {
  color: #ffffff;
}

.homepage .probootstrap-navbar .navbar-right .dropdown-menu {
  right: auto;
}

.homepage .probootstrap-navbar .dropdown-menu {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #49D292;
  border-radius: 0;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  white-space: normal;
  word-wrap: break-word;
}

.homepage .probootstrap-navbar .dropdown-menu a {
  white-space: normal;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar .dropdown-menu {
    width: 100%;
    padding: 10px 15px;
  }
  .homepage .probootstrap-navbar .dropdown-menu a {
    color: #ffffff;
  }
}

.homepage .probootstrap-navbar .navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 30px;
  text-transform: uppercase;
  background: url(../img/logo.png) no-repeat left 100%;
  top: 24px;
  position: relative;
  width: 160px;
  height: 43px;
  text-indent: -999999px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar .navbar-brand {
    top: 5px !important;
    background-position: left 100%;
  }
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-navbar .navbar-brand {
    top: 6px !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .homepage .probootstrap-navbar .navbar-brand {
    position: relative;
    width: 160px;
    height: 43px;
    background: url(../img/logo@2x.png) no-repeat left 100%;
    background-size: cover;
  }
}

.homepage .probootstrap-navbar .navbar-nav > li > a {
  color: black;
  font-size: 15px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar .navbar-nav > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    color: rgba(0, 0, 0, 0.7);
  }
}

.homepage .probootstrap-navbar .navbar-nav > li > a:hover {
  color: rgba(0, 0, 0, 0.7);
}

.homepage .probootstrap-navbar .navbar-nav > li > a:hover, .homepage .probootstrap-navbar .navbar-nav > li > a:focus, .homepage .probootstrap-navbar .navbar-nav > li > a:active {
  color: rgba(0, 0, 0, 0.7);
}

.homepage .probootstrap-navbar .navbar-nav > li.open > a:hover, .homepage .probootstrap-navbar .navbar-nav > li.open > a:focus, .homepage .probootstrap-navbar .navbar-nav > li.open > a {
  background: none;
}

.homepage .probootstrap-navbar .navbar-nav > li.active > a {
  background: none !important;
  color: #49D292 !important;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar .navbar-nav > li.active > a {
    color: rgba(0, 0, 0, 0.7) !important;
  }
}

.homepage .probootstrap-navbar .navbar-nav > li.active > a:active, .homepage .probootstrap-navbar .navbar-nav > li.active > a:focus, .homepage .probootstrap-navbar .navbar-nav > li.active > a:hover {
  background: none;
}

.homepage .probootstrap-navbar .navbar-nav > li > .dropdown-menu:before {
  border: 1px solid #49D292;
  content: "";
  position: absolute;
  top: -20px;
  left: 40px;
  border-color: rgba(73, 210, 146, 0);
  border-bottom-color: #49D292;
  border-width: 10px;
  margin-left: -10px;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar .navbar-nav > li > .dropdown-menu:before {
    display: none;
  }
}

.homepage .probootstrap-navbar .navbar-nav .dropdown li.active > a {
  background: none;
}

.homepage .probootstrap-navbar .dropdown-submenu {
  position: relative;
}

.homepage .probootstrap-navbar .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.homepage .probootstrap-navbar .dropdown-submenu > a {
  display: block;
}

.homepage .probootstrap-navbar .dropdown-submenu > a:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  margin-top: -11px;
  content: "\e926";
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar .dropdown-submenu > a:before {
    color: rgba(0, 0, 0, 0.2);
  }
}

.homepage .probootstrap-navbar .dropdown-submenu > a:hover:before {
  color: rgba(0, 0, 0, 0.3);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-navbar .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
}

.homepage .probootstrap-navbar.scrolled {
  background: #ffffff;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.09);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.09);
}

.homepage .probootstrap-navbar.scrolled .parent-nav-link-padding, .homepage .probootstrap-navbar.scrolled .navbar-brand, .homepage .probootstrap-navbar.scrolled .navbar-nav > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
}

.homepage .probootstrap-navbar.scrolled .navbar-brand {
  top: 10px;
  background-position: left 100%;
}

.homepage .probootstrap-navbar.scrolled .dropdown > a:before {
  color: rgba(0, 0, 0, 0.4);
}

.homepage .probootstrap-navbar.scrolled .navbar-nav > li > a {
  color: rgba(0, 0, 0, 0.7);
}

.homepage .probootstrap-navbar.scrolled .navbar-nav > li > a:hover {
  color: rgba(0, 0, 0, 0.7) !important;
}

.homepage .probootstrap-navbar.scrolled .navbar-nav > li.active > a {
  color: black !important;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-navbar.scrolled .navbar-nav > li.active > a {
    color: black !important;
  }
}

.homepage .container-fluid .navbar-brand {
  margin-left: 15px;
}

.homepage .container-fluid .dropdown-submenu {
  position: relative;
}

.homepage .container-fluid .dropdown-submenu .dropdown-menu {
  top: 0;
  left: auto;
  right: 100%;
  margin-top: -1px;
}

@media screen and (max-width: 480px) {
  .homepage .container-fluid .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
}

.homepage .probootstrap-hero {
  background-size: cover;
  background-position: center center;
}

.homepage .probootstrap-hero > .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.homepage .probootstrap-hero > .container {
  position: relative;
  z-index: 2;
}

.homepage .probootstrap-hero .probootstrap-hero-text {
  padding-top: 200px;
  padding-bottom: 200px;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-hero .probootstrap-hero-text {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.homepage .probootstrap-hero.probootstrap-sm-hero .probootstrap-hero-text {
  padding-top: 150px;
  padding-bottom: 150px;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-hero.probootstrap-sm-hero .probootstrap-hero-text {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.homepage .probootstrap-hero.probootstrap-xs-hero .probootstrap-hero-text {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-hero.probootstrap-xs-hero .probootstrap-hero-text {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.homepage .probootstrap-hero .btn {
  position: relative;
  font-size: 18px;
  width: 200px;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-hero .btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

.homepage .probootstrap-hero .btn.btn-primary {
  text-transform: uppercase;
  padding: 15px 20px;
}

.homepage .probootstrap-hero.probootstrap-hero-colored {
  background: #6a41ed;
}

.homepage .probootstrap-hero.probootstrap-hero-colored h1 {
  color: white;
}

.homepage .probootstrap-hero.probootstrap-hero-colored p {
  color: rgba(255, 255, 255, 0.8);
}

.homepage .probootstrap-feature-showcase {
  margin-top: 100px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-feature-showcase {
    margin-top: 0px;
  }
}

.homepage .probootstrap-home-showcase-wrap {
  width: 100%;
}

.homepage .probootstrap-home-showcase-wrap .probootstrap-home-showcase-inner {
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  z-index: 1;
  height: 500px;
  overflow: hidden;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-home-showcase-wrap .probootstrap-home-showcase-inner {
    height: 100%;
  }
}

.homepage .probootstrap-home-showcase-wrap .probootstrap-home-showcase-inner .probootstrap-chrome {
  background: #dddde0;
  padding: 15px;
  border-radius: 5px 5px 0 0;
  position: relative;
}

.homepage .probootstrap-home-showcase-wrap .probootstrap-home-showcase-inner .probootstrap-chrome > div {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -3px;
}

.homepage .probootstrap-home-showcase-wrap .probootstrap-home-showcase-inner .probootstrap-chrome > div > span {
  width: 5px;
  height: 5px;
  display: block;
  float: left;
  margin-right: 4px;
  background: #898991;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-home-showcase-wrap .probootstrap-image-showcase {
    height: 400px;
  }
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-home-showcase-wrap .probootstrap-image-showcase {
    height: 200px;
  }
}

.homepage .probootstrap-home-showcase-wrap .probootstrap-image-showcase > ul {
  padding: 0;
  margin: 0;
  position: relative;
}

.homepage .probootstrap-home-showcase-wrap .probootstrap-image-showcase > ul > li {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.homepage .probootstrap-home-showcase-wrap .probootstrap-image-showcase > ul > li img {
  max-width: 100%;
}

.homepage .probootstrap-home-showcase-wrap .probootstrap-image-showcase > ul > li.active {
  opacity: 1;
  visibility: visible;
}

.homepage .probootstrap-showcase-nav {
  margin-top: 30px;
}

.homepage .probootstrap-showcase-nav ul {
  padding: 0;
  margin: 0;
}

.homepage .probootstrap-showcase-nav ul li {
  margin: 0 0 15px 0;
  padding: 0 0 0 20px;
  list-style: none;
}

.homepage .probootstrap-showcase-nav ul li > a {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.homepage .probootstrap-showcase-nav ul li p {
  display: none;
}

.homepage .probootstrap-showcase-nav ul li.active {
  padding: 20px;
  background: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
}

.homepage .probootstrap-showcase-nav ul li.active > a {
  color: #6a41ed;
  padding-left: 0px;
  display: block;
}

.homepage .probootstrap-showcase-nav ul li.active p {
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0;
}

.homepage .btn {
  border: none;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.homepage .btn:active {
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  -webkit-box-shadow: none;
          box-shadow: none;
}

.homepage .btn.btn-lg {
  line-height: 1.5;
}

.homepage .btn.btn-primary {
  border: 1px solid #49D292;
  background: #49D292;
  color: #ffffff;
}

.homepage .btn.btn-primary:hover, .homepage .btn.btn-primary:active, .homepage .btn.btn-primary:focus {
  background: #51d497;
}

.homepage .btn.btn-primary:focus, .homepage .btn.btn-primary:active {
  outline: none;
  border: 1px solid #49D292;
}

.homepage .btn.btn-ghost {
  background: transparent;
  border: 1px solid #49D292;
  color: #49D292;
}

.homepage .btn.btn-ghost:hover {
  background: #49D292;
  color: #ffffff;
}

.homepage .btn.btn-ghost:focus, .homepage .btn.btn-ghost:active {
  outline: none;
  background: transparent;
  color: #49D292;
  border: 1px solid #49D292;
}

.homepage .probootstrap-section {
  padding: 7em 0;
  position: relative;
}

.homepage .probootstrap-section.probootstrap-section-sm {
  padding: 3em 0;
}

.homepage .probootstrap-section.probootstrap-border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.homepage .probootstrap-section.probootstrap-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.homepage .probootstrap-section > .container.probootstrap-border-top {
  padding-top: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.homepage .probootstrap-section.probootstrap-section-colored {
  background: #6a41ed;
}

.homepage .probootstrap-section.probootstrap-section-colored .section-heading h2 {
  color: white;
}

.homepage .probootstrap-section.probootstrap-section-colored .section-heading p {
  color: rgba(255, 255, 255, 0.6);
}

.homepage .probootstrap-section.probootstrap-bg-white {
  background: #ffffff;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-section {
    padding: 3em 0;
  }
}

.homepage .probootstrap-section.probootstrap-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.homepage .probootstrap-section.probootstrap-bg:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.homepage .section-heading {
  margin-bottom: 70px;
}

.homepage .section-heading h1 {
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .homepage .section-heading h1 {
    font-size: 24px;
    line-height: 24px;
  }
}

.homepage .section-heading h2 {
  line-height: 40px;
  font-size: 40px;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .homepage .section-heading h2 {
    font-size: 24px;
    line-height: 24px;
  }
}

@media screen and (max-width: 768px) {
  .homepage .section-heading p {
    font-size: 16px;
  }
}

.homepage .lead {
  font-size: 18px;
  line-height: 32px;
}

.homepage .probootstrap-cta {
  padding: 3em 0;
  background: #6a41ed;
  color: #ffffff;
}

.homepage .probootstrap-cta h2 {
  margin: 12px 0 0 0;
  padding: 0;
  color: #ffffff;
  float: left;
  line-height: 1.5;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-cta h2 {
    float: none;
    width: 100%;
    margin: 0px 0 0 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-cta h2 {
    text-align: center;
  }
}

.homepage .probootstrap-cta .btn {
  float: right;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-cta .btn {
    float: none;
    width: 100%;
    margin-top: 0px;
  }
}

.homepage .probootstrap-cta .btn.btn-ghost {
  width: 200px;
  border: 1px solid #ffffff;
  color: #ffffff;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-cta .btn.btn-ghost {
    width: 100%;
  }
}

.homepage .probootstrap-cta .btn.btn-ghost:hover {
  background: #ffffff;
  color: #6a41ed;
}

.homepage .probootstrap-footer {
  padding: 7em 0;
  padding-bottom: 0;
  background: #000000;
  color: rgba(255, 255, 255, 0.9);
}

.homepage .probootstrap-footer a {
  color: rgba(255, 255, 255, 0.6);
}

.homepage .probootstrap-footer a:hover {
  color: white;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-footer {
    padding: 3em 0;
  }
}

.homepage .probootstrap-footer .probootstrap-copyright {
  padding: 3em 0;
  margin-top: 20px;
  background: #1a1a1a;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-footer .probootstrap-copyright {
    margin-top: 0px;
  }
}

.homepage .probootstrap-footer .probootstrap-copyright p {
  line-height: 20px;
}

.homepage .probootstrap-footer .probootstrap-copyright p .icon {
  position: relative;
  top: 2px;
}

.homepage .probootstrap-footer .probootstrap-copyright p:last-child {
  margin-bottom: 0;
}

.homepage .probootstrap-footer .probootstrap-footer-widget {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.homepage .probootstrap-footer .probootstrap-footer-widget h3 {
  color: white;
  font-size: 24px;
  font-weight: 300;
}

.homepage .probootstrap-footer .probootstrap-footer-widget ul {
  margin: 0;
  padding: 0 0 20px 0;
}

.homepage .probootstrap-footer .probootstrap-footer-widget ul li {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
}

.homepage .probootstrap-footer .probootstrap-footer-widget .probootstrap-contact-info li {
  display: block;
}

.homepage .probootstrap-footer .probootstrap-footer-widget .probootstrap-contact-info li i {
  display: table-cell;
  vertical-align: top;
  width: 40px;
  color: rgba(255, 255, 255, 0.3);
}

.homepage .probootstrap-footer .probootstrap-footer-widget .probootstrap-contact-info li span {
  vertical-align: top;
  display: table-cell;
}

.homepage .probootstrap-footer .probootstrap-back-to-top {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-footer .probootstrap-back-to-top {
    text-align: left;
    margin-top: 30px;
  }
}

.homepage .probootstrap-search {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 9999;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

.homepage .probootstrap-search.active {
  opacity: 1;
  visibility: visible;
}

.homepage .probootstrap-search .probootstrap-close {
  color: #b3b3b3;
  right: 30px;
  top: 30px;
  position: absolute;
  font-size: 28px;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-search .probootstrap-close {
    right: 20px;
    top: 20px;
  }
}

.homepage .probootstrap-search .probootstrap-close:hover {
  color: gray;
}

.homepage .probootstrap-search form {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  width: 70%;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-search form {
    width: 80%;
  }
}

.homepage .probootstrap-search form input {
  color: #000000;
  width: 100%;
  font-size: 50px;
  height: 100px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0px !important;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-search form input {
    font-size: 18px;
    height: 50px;
  }
}

.homepage .probootstrap-search form input:focus, .homepage .probootstrap-search form input:active {
  outline: none;
}

.homepage .probootstrap-uppercase {
  color: #b3b3b3;
  font-size: 14px;
  margin-bottom: 5px;
}

.homepage .probootstrap-footer-social {
  padding: 0;
  margin: 0;
}

.homepage .probootstrap-footer-social li {
  display: inline;
  padding: 0;
  margin: 0;
  list-style: none;
}

.homepage .probootstrap-footer-social li a {
  padding: 10px;
  font-size: 22px;
}

.homepage .probootstrap-footer-social li:first-child > a {
  padding-left: 0;
}

.homepage .probootstrap-teacher {
  padding: 20px;
  border: 1px solid #e6e6e6;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  position: relative;
  top: 0;
  margin-bottom: 20px;
}

.homepage .probootstrap-teacher .media {
  margin-bottom: 20px;
}

.homepage .probootstrap-teacher .media img {
  border-radius: 50%;
  width: 90px;
  margin: 0 auto;
}

.homepage .probootstrap-teacher .text h3 {
  font-size: 20px;
  margin: 0 0 0px 0;
}

.homepage .probootstrap-teacher .text p {
  margin-bottom: 10px;
}

.homepage .probootstrap-teacher .probootstrap-footer-social li a {
  font-size: 15px;
}

.homepage .probootstrap-teacher .probootstrap-footer-social li.twitter a {
  color: #1da1f2;
}

.homepage .probootstrap-teacher .probootstrap-footer-social li.facebook a {
  color: #3b5998;
}

.homepage .probootstrap-teacher .probootstrap-footer-social li.instagram a {
  color: #e1306c;
}

.homepage .probootstrap-teacher .probootstrap-footer-social li.google-plus a {
  color: #dd4b39;
}

.homepage .probootstrap-teacher:focus, .homepage .probootstrap-teacher:hover {
  top: -10px;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.homepage .probootstrap-counter-wrap {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.homepage .probootstrap-counter-wrap .probootstrap-icon,
.homepage .probootstrap-counter-wrap .probootstrap-text {
  display: table-cell;
  vertical-align: top;
}

.homepage .probootstrap-counter-wrap .probootstrap-icon {
  width: 60px;
}

.homepage .probootstrap-counter-wrap .probootstrap-icon i {
  font-size: 40px;
  color: #49D292;
}

.homepage .probootstrap-counter-wrap .probootstrap-text .probootstrap-counter {
  display: block;
  font-size: 40px;
  color: #000000;
  margin-bottom: 10px;
  font-family: "Open Sans", Arial, sans-serif;
}

.homepage .probootstrap-counter-wrap .probootstrap-text .probootstrap-counter-label {
  display: block;
  color: #b3b3b3;
  line-height: 20px;
}

.homepage .probootstrap-overlap {
  margin-top: -150px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-overlap {
    margin-top: -100px;
  }
}

.homepage .probootstrap-flex-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: -200px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-flex-block {
    margin-top: -100px;
  }
}

.homepage .probootstrap-flex-block .probootstrap-text {
  width: 30%;
  margin-right: 20px;
  background: #ffffff;
  padding: 40px;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-flex-block .probootstrap-text {
    width: 100%;
    margin-right: 0;
  }
}

.homepage .probootstrap-flex-block .probootstrap-text h3 {
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 20px;
}

.homepage .probootstrap-flex-block .probootstrap-text p:last-child {
  margin-bottom: 0;
}

.homepage .probootstrap-flex-block .probootstrap-image {
  width: 67%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 0px;
  position: relative;
}

.homepage .probootstrap-flex-block .probootstrap-image .btn-video {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.homepage .probootstrap-flex-block .probootstrap-image .btn-video i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 70px;
  color: white;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.homepage .probootstrap-flex-block .probootstrap-image .btn-video:before {
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.homepage .probootstrap-flex-block .probootstrap-image .btn-video:hover:before {
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-flex-block .probootstrap-image {
    width: 100%;
    height: 300px;
  }
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-flex-block .probootstrap-image {
    height: 200px;
  }
}

.homepage .nav-tabs.probootstrap-center,
.homepage .nav-pills.probootstrap-center {
  text-align: center;
}

.homepage .nav-tabs.probootstrap-center > li,
.homepage .nav-pills.probootstrap-center > li {
  float: none;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

@media screen and (max-width: 480px) {
  .homepage .nav-tabs.probootstrap-center > li,
  .homepage .nav-pills.probootstrap-center > li {
    width: 100%;
    display: block;
  }
}

.homepage .nav-tabs.probootstrap-tabs > li > a,
.homepage .nav-pills.probootstrap-tabs > li > a {
  border-radius: 0px;
  padding: 20px 30px;
  background: #49D292;
  color: #ffffff;
  font-size: 16px;
  border: none !important;
}

@media screen and (max-width: 480px) {
  .homepage .nav-tabs.probootstrap-tabs > li > a,
  .homepage .nav-pills.probootstrap-tabs > li > a {
    width: 100%;
    display: block;
  }
}

.homepage .nav-tabs.probootstrap-tabs > li > a:hover,
.homepage .nav-pills.probootstrap-tabs > li > a:hover {
  background: #35cd86;
}

.homepage .nav-tabs.probootstrap-tabs > li > a:active, .homepage .nav-tabs.probootstrap-tabs > li > a:focus,
.homepage .nav-pills.probootstrap-tabs > li > a:active,
.homepage .nav-pills.probootstrap-tabs > li > a:focus {
  outline: none;
}

.homepage .nav-tabs.probootstrap-tabs > li.active > a,
.homepage .nav-pills.probootstrap-tabs > li.active > a {
  background: #ffffff;
  color: #000000;
}

.homepage .nav-tabs.probootstrap-tabs.no-border,
.homepage .nav-pills.probootstrap-tabs.no-border {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-tab-section {
    padding-bottom: 0 !important;
  }
}

.homepage .probootstrap-tab-style-1 {
  position: absolute;
  bottom: 1px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-tab-style-1 {
    position: relative;
  }
}

.homepage .probootstrap-date,
.homepage .probootstrap-location {
  display: block;
  color: #666666;
}

.homepage .probootstrap-date i,
.homepage .probootstrap-location i {
  color: #cccccc;
  position: relative;
  top: 2px;
  width: 30px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.homepage .probootstrap-featured-news-box .probootstrap-media {
  position: relative;
  z-index: 1;
}

.homepage .probootstrap-featured-news-box .probootstrap-text {
  position: relative;
  z-index: 2;
  background: #ffffff;
  padding: 20px;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  margin-left: 10px;
  margin-right: 10px;
  top: -30px;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  border-bottom: 3px solid #6a41ed;
}

.homepage .probootstrap-featured-news-box .probootstrap-text h3 {
  font-size: 18px;
  margin: 0 0 10px 0;
  line-height: 22px;
}

.homepage .probootstrap-featured-news-box .probootstrap-text p {
  color: #666666;
}

.homepage .probootstrap-featured-news-box:hover, .homepage .probootstrap-featured-news-box:focus {
  outline: none;
}

.homepage .probootstrap-featured-news-box:hover .probootstrap-text, .homepage .probootstrap-featured-news-box:focus .probootstrap-text {
  background: #6a41ed;
  top: -40px;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.homepage .probootstrap-featured-news-box:hover .probootstrap-text h3, .homepage .probootstrap-featured-news-box:focus .probootstrap-text h3 {
  font-size: 18px;
  margin: 0 0 10px 0;
  color: #ffffff;
}

.homepage .probootstrap-featured-news-box:hover .probootstrap-text p, .homepage .probootstrap-featured-news-box:focus .probootstrap-text p {
  color: rgba(255, 255, 255, 0.7);
}

.homepage .probootstrap-featured-news-box:hover .probootstrap-text .probootstrap-date,
.homepage .probootstrap-featured-news-box:hover .probootstrap-text .probootstrap-location, .homepage .probootstrap-featured-news-box:focus .probootstrap-text .probootstrap-date,
.homepage .probootstrap-featured-news-box:focus .probootstrap-text .probootstrap-location {
  color: rgba(255, 255, 255, 0.8);
}

.homepage .probootstrap-featured-news-box:hover .probootstrap-text .probootstrap-date i,
.homepage .probootstrap-featured-news-box:hover .probootstrap-text .probootstrap-location i, .homepage .probootstrap-featured-news-box:focus .probootstrap-text .probootstrap-date i,
.homepage .probootstrap-featured-news-box:focus .probootstrap-text .probootstrap-location i {
  color: rgba(255, 255, 255, 0.4);
}

.homepage .service {
  margin-bottom: 30px;
  float: left;
  width: 100%;
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.homepage .service .icon {
  display: block;
  margin-bottom: 30px;
}

.homepage .service .icon i {
  font-size: 40px;
  color: rgba(0, 0, 0, 0.2);
  color: #49D292;
}

.homepage .service h3 {
  font-size: 22px;
  margin-bottom: 20px;
}

.homepage .service p {
  font-size: 15px;
}

.homepage .service.left-icon {
  padding: 0px;
  border: none;
  margin-bottom: 20px !important;
}

.homepage .service.left-icon .icon,
.homepage .service.left-icon .text {
  display: table-cell;
  vertical-align: top;
}

.homepage .service.left-icon .icon {
  width: 70px;
  position: relative;
  top: 20px;
}

.homepage .service.left-icon .icon i {
  font-size: 40px;
}

.homepage .service.left-icon .text {
  display: table-cell;
}

.homepage .service.left-icon h3 {
  font-size: 22px;
  margin-bottom: 0px;
}

.homepage .service.left-icon p {
  font-size: 14px;
}

.homepage .service.left-icon p:last-child {
  margin-bottom: 0;
}

.homepage .service.hover_service {
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.homepage .service.hover_service:hover, .homepage .service.hover_service:focus {
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.homepage .probootstrap-service-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.homepage .probootstrap-service-2 .image {
  width: 40%;
}

.homepage .probootstrap-service-2 .text {
  width: 60%;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-service-2 .image,
  .homepage .probootstrap-service-2 .text {
    width: 100%;
  }
}

.homepage .probootstrap-service-2 .image {
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-service-2 .image {
    width: 100%;
    height: 200px;
  }
}

.homepage .probootstrap-service-2 .image .image-bg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.homepage .probootstrap-service-2 .image .image-bg img {
  min-width: 100%;
  min-height: 100%;
}

.homepage .probootstrap-service-2 .text {
  padding: 20px;
}

.homepage .probootstrap-service-2 .text h3 {
  font-size: 20px;
  margin: 0;
}

.homepage .probootstrap-service-2 .text p {
  margin-bottom: 20px;
}

.homepage .probootstrap-service-2 .text p:last-child {
  margin-bottom: 0;
}

.homepage .probootstrap-service-2 .text .probootstrap-meta {
  font-size: 14px;
  color: #b3b3b3;
}

.homepage .enrolled-count {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  color: #b3b3b3;
  font-family: "Open Sans", Arial, sans-serif;
  font-style: italic;
}

.homepage .probootstrap-gallery {
  width: 100%;
  float: left;
}

.homepage .probootstrap-gallery figure {
  display: block;
  float: left;
  width: 32%;
  margin-right: 2%;
  float: left;
  margin-bottom: 20px;
}

.homepage .probootstrap-gallery figure:nth-of-type(3n + 3) {
  margin-right: 0;
}

.homepage .probootstrap-gallery figure figcaption {
  display: none;
}

.homepage .probootstrap-gallery img {
  width: 100%;
  height: auto;
}

.homepage .probootstrap-gallery.four-col figure {
  width: 23.5%;
  margin-right: 2%;
  float: left;
}

.homepage .probootstrap-gallery.four-col figure:nth-of-type(4n + 4) {
  margin-right: 0;
}

.homepage .probootstrap-gallery.three-col figure {
  width: 32%;
  margin-right: 2%;
  float: left;
}

.homepage .probootstrap-gallery.three-col figure:nth-of-type(3n + 3) {
  margin-right: 0;
}

.homepage .probootstrap-gallery.two-col figure {
  width: 49%;
  margin-right: 2%;
  float: left;
}

.homepage .probootstrap-gallery.two-col figure:nth-of-type(2n + 2) {
  margin-right: 0;
}

.homepage .grid-item {
  float: left;
}

.homepage .gutter-sizer {
  width: 2%;
}

.homepage .isotope .isotope-item {
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.homepage .isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

.homepage .grid-item,
.homepage .grid-sizer {
  margin-bottom: 0;
}

.homepage .grid-item img,
.homepage .grid-sizer img {
  max-width: 100%;
  margin-bottom: 0px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.two-cols .homepage .grid-item, .two-cols
.homepage .grid-sizer {
  width: 49%;
}

@media screen and (max-width: 768px) {
  .two-cols .homepage .grid-item, .two-cols
  .homepage .grid-sizer {
    width: 49%;
  }
}

@media screen and (max-width: 768px) and (max-width: 992px) {
  .two-cols .homepage .grid-item img, .two-cols
  .homepage .grid-sizer img {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .two-cols .homepage .grid-item img, .two-cols
  .homepage .grid-sizer img {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .two-cols .homepage .grid-item, .two-cols
  .homepage .grid-sizer {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.three-cols .homepage .grid-item, .three-cols
.homepage .grid-sizer {
  width: 32%;
}

@media screen and (max-width: 768px) {
  .three-cols .homepage .grid-item, .three-cols
  .homepage .grid-sizer {
    width: 48%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .three-cols .homepage .grid-item, .three-cols
  .homepage .grid-sizer {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.four-cols .homepage .grid-item, .four-cols
.homepage .grid-sizer {
  width: 23.5%;
}

@media screen and (max-width: 768px) {
  .four-cols .homepage .grid-item, .four-cols
  .homepage .grid-sizer {
    width: 32%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .four-cols .homepage .grid-item, .four-cols
  .homepage .grid-sizer {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.homepage .probootstrap-gallery-item {
  padding: 0;
  margin: 0;
  list-style: none;
  float: left;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
}

.homepage .probootstrap-gallery-item img {
  max-width: 100%;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.homepage .probootstrap-gallery-item a {
  display: block;
  float: left;
  width: 100%;
}

.homepage .probootstrap-gallery-item:hover img {
  opacity: 0.7;
}

.homepage .probootstrap-form {
  margin-bottom: 50px;
}

.homepage .probootstrap-form label {
  font-weight: normal;
}

.homepage .probootstrap-form .form-control {
  height: 46px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-size: 16px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.homepage .probootstrap-form .form-control:active, .homepage .probootstrap-form .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #6a41ed;
}

.homepage .probootstrap-form textarea.form-control {
  height: inherit;
  resize: vertical;
}

.homepage .probootstrap-animate {
  opacity: 0;
  visibility: hidden;
}

.homepage .modal .modal-content {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
  border: none;
  position: relative;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
}

.homepage .modal .modal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  font-size: 26px;
}

@media screen and (max-width: 480px) {
  .homepage .modal .modal-content .close {
    top: 20px;
    right: 20px;
  }
}

.homepage .modal .probootstrap-modal-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.homepage .modal .probootstrap-modal-flex .probootstrap-modal-figure {
  width: 40%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 480px) {
  .homepage .modal .probootstrap-modal-flex .probootstrap-modal-figure {
    height: 200px;
  }
}

.homepage .modal .probootstrap-modal-flex .probootstrap-modal-content {
  width: 60%;
  padding: 40px;
  position: relative;
}

@media screen and (max-width: 480px) {
  .homepage .modal .probootstrap-modal-flex .probootstrap-modal-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 480px) {
  .homepage .modal .probootstrap-modal-flex .probootstrap-modal-figure,
  .homepage .modal .probootstrap-modal-flex .probootstrap-modal-content {
    width: 100%;
  }
}

.homepage .modal .probootstrap-modal-flex .form-control {
  height: 40px;
}

@media screen and (max-width: 480px) {
  .homepage .modal .probootstrap-modal-flex .btn {
    margin-bottom: 10px;
  }
}

.homepage .modal .probootstrap-modal-flex .probootstrap-remember {
  float: left;
}

.homepage .modal .probootstrap-modal-flex .probootstrap-forgot {
  float: right;
}

@media screen and (max-width: 480px) {
  .homepage .modal .probootstrap-modal-flex .probootstrap-forgot {
    float: left;
  }
}

.homepage .modal .probootstrap-modal-flex .form-group {
  position: relative;
}

.homepage .modal .probootstrap-modal-flex .probootstrap-or {
  padding: 10px 0;
  text-align: center;
  display: block;
  font-size: 11px;
  text-transform: uppercase;
}

.homepage .modal .probootstrap-modal-flex .probootstrap-or > span {
  display: block;
}

.homepage .modal .probootstrap-modal-flex .probootstrap-or > span:before {
  height: 1px;
  left: 0;
  right: 0;
  top: 50%;
  background: #ccc;
  width: 100%;
  content: "";
  position: absolute;
}

.homepage .modal .probootstrap-modal-flex .probootstrap-or em {
  color: #ccc;
  display: inline-block;
  background: #ffffff;
  position: relative;
  z-index: 2;
  padding: 0 4px;
  font-style: normal;
}

.homepage .modal .probootstrap-modal-flex .btn-connect-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #ffffff;
}

.homepage .modal .probootstrap-modal-flex .btn-connect-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #ffffff;
}

.homepage .modal .probootstrap-modal-flex .btn-connect-google {
  background: #ea4335;
  border-color: #ea4335;
  color: #ffffff;
}

.homepage .modal .vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
  pointer-events: none;
  /* This makes sure that we can still click outside of the modal to close it */
}

.homepage .modal .vertical-align-center {
  /* To center vertically */
  display: table-cell;
  vertical-align: middle;
  pointer-events: none;
}

.homepage .modal .modal-content {
  /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
  width: inherit;
  height: inherit;
  /* To center horizontally */
  margin: 0 auto;
  pointer-events: all;
}

.homepage .probootstrap-contact-info {
  padding: 0;
  margin: 0 0 30px 0;
}

.homepage .probootstrap-contact-info li {
  padding: 0;
  margin: 20px 0 15px 0;
  list-style: none;
  line-height: 24px;
}

.homepage .probootstrap-contact-info li > i,
.homepage .probootstrap-contact-info li > span {
  vertical-align: top;
  display: table-cell;
}

.homepage .probootstrap-contact-info li > i {
  font-size: 20px;
  position: relative;
  top: 2px;
  width: 30px;
}

.homepage .probootstrap-team {
  margin-bottom: 30px;
  float: left;
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 2;
  border-radius: 6px;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-team {
    overflow: visible;
  }
  .homepage .probootstrap-team img {
    max-width: 100%;
  }
}

.homepage .probootstrap-team img {
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
          transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.homepage .probootstrap-team .probootstrap-team-info {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
          transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
  text-align: center;
  margin-top: 20px;
  z-index: 3;
  display: block;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-team .probootstrap-team-info {
    position: relative;
    top: inherit !important;
    margin-top: 0 !important;
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

.homepage .probootstrap-team .probootstrap-team-info h3 {
  color: white;
}

.homepage .probootstrap-team .probootstrap-team-info h3 .position {
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-team .probootstrap-team-info h3 {
    color: rgba(0, 0, 0, 0.7);
  }
  .homepage .probootstrap-team .probootstrap-team-info h3 .position {
    display: block;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }
}

.homepage .probootstrap-team:before {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  z-index: 1;
  border-radius: 5px;
}

.homepage .probootstrap-team:hover img, .homepage .probootstrap-team:focus img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.homepage .probootstrap-team:hover:before, .homepage .probootstrap-team:focus:before {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-team:hover:before, .homepage .probootstrap-team:focus:before {
    display: none;
  }
}

.homepage .probootstrap-team:hover .probootstrap-team-info, .homepage .probootstrap-team:focus .probootstrap-team-info {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.homepage .probootstrap-flex-section {
  padding: 7em 0;
}

.homepage .probootstrap-flex-section.probootstrap-bg-white {
  background: #ffffff;
}

.homepage .probootstrap-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.homepage .probootstrap-flex .probootstrap-flex-item {
  width: 33.3333%;
  padding: 40px;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
          transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
  z-index: 1;
  position: relative;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-flex .probootstrap-flex-item {
    padding: 30px;
  }
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-flex .probootstrap-flex-item {
    padding: 20px;
    margin-bottom: 20px;
  }
}

.homepage .probootstrap-flex .probootstrap-flex-item .service {
  margin-bottom: 0;
}

.homepage .probootstrap-flex .probootstrap-flex-item p:last-child {
  margin-bottom: 0;
}

.homepage .probootstrap-flex .probootstrap-flex-item.active, .homepage .probootstrap-flex .probootstrap-flex-item:hover, .homepage .probootstrap-flex .probootstrap-flex-item:focus {
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;
  background: #ffffff;
}

@media screen and (max-width: 768px) {
  .homepage .probootstrap-flex .probootstrap-flex-item {
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-flex .probootstrap-flex-item {
    width: 100%;
  }
}

.homepage .proboostrap-clients .client-logo {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .homepage .proboostrap-clients .client-logo {
    margin-bottom: 30px;
  }
}

.homepage .probootstrap-pricing-wrap {
  position: relative;
}

.homepage .probootstrap-pricing {
  background: #ffffff;
  float: left;
  width: 100%;
  padding: 20px;
  text-align: center;
  position: relative;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;
  margin-top: -200px;
}

@media screen and (max-width: 992px) {
  .homepage .probootstrap-pricing {
    margin-top: 0 !important;
    margin-bottom: 30px;
  }
}

.homepage .probootstrap-pricing.popular {
  z-index: 10;
  margin-top: -215px;
  padding-top: 40px;
}

@media screen and (max-width: 992px) {
  .homepage .probootstrap-pricing.popular {
    margin-top: 0 !important;
  }
}

.homepage .probootstrap-pricing h3 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 50px;
}

.homepage .probootstrap-pricing h3 > span {
  margin-top: 10px;
  display: block;
  text-transform: none;
  letter-spacing: normal;
  color: #6a41ed;
  font-size: 14px;
}

.homepage .probootstrap-pricing .probootstrap-price-wrap {
  margin-bottom: 50px;
}

.homepage .probootstrap-pricing .probootstrap-price-wrap .probootstrap-price {
  font-size: 50px;
  color: #6a41ed;
  font-weight: 100;
  display: block;
  margin-bottom: 10px;
}

.homepage .probootstrap-pricing .probootstrap-price-wrap .probootstrap-price-per-month {
  font-size: 13px;
}

.homepage .probootstrap-pricing ul {
  padding: 0 30px;
  margin: 0 0 50px 0;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-pricing ul {
    padding: 0 10px;
  }
}

.homepage .probootstrap-pricing ul li {
  padding: 0;
  margin: 0 0 20px 0;
  line-height: 24px;
  list-style: none;
}

.homepage .probootstrap-testimony-wrap {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
}

.homepage .probootstrap-testimony-wrap figure {
  margin-bottom: 20px;
}

.homepage .probootstrap-testimony-wrap figure img {
  height: 80px;
  width: 80px !important;
  margin: 0 auto;
  border-radius: 50%;
}

.homepage .probootstrap-testimony-wrap blockquote {
  border-left: none;
  padding: 0;
  margin-bottom: 0;
  color: #000000;
}

.homepage .probootstrap-testimony-wrap blockquote cite {
  margin-top: 30px;
  display: block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}

.homepage .probootstrap-testimony-wrap blockquote cite span {
  font-style: normal;
  color: #000000;
  font-weight: bold;
}

.homepage .owl-carousel-testimony .item {
  padding-bottom: 40px;
  padding-top: 20px;
}

.homepage .owl-carousel-testimony .probootstrap-testimony-wrap {
  max-width: 700px;
  margin: 0 auto;
}

.homepage .slider-height, .homepage .flexslider, .homepage .flexslider .slides > li {
  height: 700px;
}

@media screen and (max-width: 480px) {
  .homepage .slider-height, .homepage .flexslider, .homepage .flexslider .slides > li {
    height: 400px;
  }
}

.homepage .flexslider {
  position: relative;
  z-index: 2;
  background: none;
  border: none;
  margin: 0 0 0;
}

.homepage .flexslider .probootstrap-subheading {
  font-size: 20px;
}

.homepage .flexslider .probootstrap-slider-text {
  margin-top: 300px;
}

@media screen and (max-width: 480px) {
  .homepage .flexslider .probootstrap-slider-text {
    margin-top: 150px;
  }
}

.homepage .flexslider .slides > li {
  background-size: cover;
  background-position: center center;
  background-repeat: none;
}

.homepage .flexslider .slides > li.overlay:before {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.homepage .flexslider .flex-control-paging {
  position: absolute;
  bottom: 30px;
  z-index: 20;
}

@media screen and (max-width: 480px) {
  .homepage .flexslider .flex-direction-nav {
    display: none;
  }
}

.homepage .flexslider .flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0px;
  padding: 30px;
}

.homepage .flexslider .flex-direction-nav a:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  content: "\e929";
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  font-size: 30px;
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.homepage .flexslider .flex-direction-nav a.flex-next:before {
  content: "\e92a";
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.homepage .flex-direction-nav .flex-prev {
  left: 0px;
}

.homepage .flex-direction-nav .flex-next {
  right: 0px;
  text-align: right;
}

.homepage .flexslider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 0px;
}

.homepage .flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

.homepage .flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 0px;
}

.homepage .flex-control-paging li a {
  width: 10px;
  height: 10px;
  display: block;
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.homepage .flex-control-paging li a:hover {
  background: #333;
  background: rgba(255, 255, 255, 0.7);
}

.homepage .flex-control-paging li a.flex-active {
  background: #000;
  background: white;
  cursor: default;
}

.homepage .owl-carousel {
  margin-bottom: 100px;
}

.homepage .owl-carousel.border-rounded .item {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.homepage .owl-carousel .owl-controls,
.homepage .owl-carousel-posts .owl-controls {
  margin-top: 0;
}

.homepage .owl-carousel .owl-controls .owl-nav .owl-next,
.homepage .owl-carousel .owl-controls .owl-nav .owl-prev,
.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-next,
.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-prev {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 9999;
  position: absolute;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-next,
.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-prev {
  top: 24%;
}

.homepage .owl-carousel .owl-controls .owl-nav .owl-next,
.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-next {
  right: 20px;
}

.homepage .owl-carousel .owl-controls .owl-nav .owl-prev,
.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-prev {
  left: 20px;
}

.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-next i,
.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-prev i,
.homepage .owl-carousel-fullwidth .owl-controls .owl-nav .owl-next i,
.homepage .owl-carousel-fullwidth .owl-controls .owl-nav .owl-prev i {
  color: #000000;
}

.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-next:hover i,
.homepage .owl-carousel-posts .owl-controls .owl-nav .owl-prev:hover i,
.homepage .owl-carousel-fullwidth .owl-controls .owl-nav .owl-next:hover i,
.homepage .owl-carousel-fullwidth .owl-controls .owl-nav .owl-prev:hover i {
  color: #000000;
}

.homepage .owl-carousel-fullwidth.fh5co-light-arrow .owl-controls .owl-nav .owl-next i,
.homepage .owl-carousel-fullwidth.fh5co-light-arrow .owl-controls .owl-nav .owl-prev i {
  color: #ffffff;
}

.homepage .owl-carousel-fullwidth.fh5co-light-arrow .owl-controls .owl-nav .owl-next:hover i,
.homepage .owl-carousel-fullwidth.fh5co-light-arrow .owl-controls .owl-nav .owl-prev:hover i {
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .homepage .owl-theme .owl-controls .owl-nav {
    display: none;
  }
}

.homepage .owl-theme .owl-controls .owl-nav [class*="owl-"] {
  background: none !important;
}

.homepage .owl-theme .owl-controls .owl-nav [class*="owl-"] i {
  font-size: 24px;
  background: rgba(0, 0, 0, 0.7) !important;
  padding: 12px;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
}

.homepage .owl-theme .owl-controls .owl-nav [class*="owl-"]:hover i, .homepage .owl-theme .owl-controls .owl-nav [class*="owl-"]:focus i {
  background: rgba(0, 0, 0, 0.7) !important;
}

.homepage .owl-theme .owl-dots {
  position: absolute;
  bottom: -30px;
  width: 100%;
  text-align: center;
}

.homepage .owl-carousel-fullwidth.owl-theme .owl-dots {
  bottom: -30px;
  margin-bottom: -2.5em;
}

.homepage .owl-work.owl-theme .owl-dots {
  bottom: -30px;
  margin-bottom: -2.5em;
}

.homepage .owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  border: 2px solid transparent;
}

.homepage .owl-theme .owl-dots .owl-dot span:hover {
  background: none;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.homepage .owl-theme .owl-dots .owl-dot.active span,
.homepage .owl-theme .owl-dots .owl-dot:hover span {
  background: none;
  border: 2px solid #000000;
}

.homepage .probootstrap-testimonial:before {
  background: rgba(0, 0, 0, 0.7) !important;
}

.homepage .probootstrap-testimonial .section-heading p {
  color: rgba(255, 255, 255, 0.9) !important;
}

.homepage .probootstrap-testimonial .owl-theme .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.2);
}

.homepage .probootstrap-testimonial .owl-theme .owl-dots .owl-dot span:hover {
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.homepage .probootstrap-testimonial .owl-theme .owl-dots .owl-dot.active span,
.homepage .probootstrap-testimonial .owl-theme .owl-dots .owl-dot:hover span {
  background: none;
  border: 2px solid #ffffff;
}

.homepage .mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.homepage .mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.homepage .mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.homepage .mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.homepage .mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.homepage .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.homepage .image-link {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.homepage .mfp-with-zoom .mfp-container,
.homepage .mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.homepage .mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.homepage .mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.homepage .mfp-with-zoom.mfp-removing .mfp-container,
.homepage .mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.homepage .mfp-no-margins img.mfp-img {
  padding: 0;
}

.homepage .mfp-no-margins .mfp-figure:after {
  top: 0;
  bottom: 0;
}

.homepage .mfp-no-margins .mfp-container {
  padding: 0;
}

.homepage .mfp-title {
  text-align: center;
  padding: 6px 0;
}

.homepage .image-source-link {
  color: #ddd;
}

.homepage .probootstrap-gutter0 > div[class*="col-"] {
  padding-right: 0px;
  padding-left: 0px;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-gutter0 > div[class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.homepage .probootstrap-gutter40 > div[class*="col-"] {
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-gutter40 > div[class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.homepage .probootstrap-gutter60 > div[class*="col-"] {
  padding-right: 30px;
  padding-left: 30px;
}

@media screen and (max-width: 480px) {
  .homepage .probootstrap-gutter60 > div[class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.homepage .mb0 {
  margin-bottom: 0px;
}

.homepage .mb10 {
  margin-bottom: 10px;
}

.homepage .mb20 {
  margin-bottom: 20px;
}

.homepage .mb30 {
  margin-bottom: 30px;
}

.homepage .mb40 {
  margin-bottom: 40px;
}

.homepage .mb50 {
  margin-bottom: 50px;
}

.homepage .mb60 {
  margin-bottom: 60px;
}

.homepage .mb70 {
  margin-bottom: 70px;
}

.homepage .mb80 {
  margin-bottom: 80px;
}

.homepage .mb90 {
  margin-bottom: 90px;
}

.homepage .mb100 {
  margin-bottom: 100px;
}

.homepage .mt0 {
  margin-top: 0px;
}

.homepage .mt10 {
  margin-top: 10px;
}

.homepage .mt20 {
  margin-top: 20px;
}

.homepage .mt30 {
  margin-top: 30px;
}

.homepage .mt40 {
  margin-top: 40px;
}

.homepage .mt50 {
  margin-top: 50px;
}

.homepage .mt60 {
  margin-top: 60px;
}

.homepage .mt70 {
  margin-top: 70px;
}

.homepage .mt80 {
  margin-top: 80px;
}

.homepage .mt90 {
  margin-top: 90px;
}

.homepage .mt100 {
  margin-top: 100px;
}

.homepage .pb0 {
  padding-bottom: 0 !important;
}

@media screen and (max-width: 768px) {
  .homepage .img-sm-responsive {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .homepage .img-xs-responsive {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .homepage .col-xxs-12 {
    float: none;
    width: 100%;
  }
}
/*# sourceMappingURL=style.css.map */