@media print {
  .noprint {
    display: none !important;
  }
}
.printoptions {
  position: fixed;
  top: 5%;
  z-index: 999;
  width: "150px";
  padding: 0.5rem;
  text-align: center;
  border-radius: 5px;
  opacity: 0.1;
}
.printoptions:hover,
.printoptions:focus {
  opacity: 1;
}
.attendance td {
  padding: 0.2rem;
}
